@use "./breakpoints";

@mixin grid-mixin($prefix) {
  .#{$prefix}col {
    float: left;
    box-sizing: border-box;
  }

  .#{$prefix}col-right {
    float: right;
    box-sizing: border-box;
  }

  .#{$prefix}col-1 {
    width: calc(1 / 12 * 100%);
  }

  .#{$prefix}col-2 {
    width: calc(2 / 12 * 100%);
  }

  .#{$prefix}col-3 {
    width: calc(3 / 12 * 100%);
  }

  .#{$prefix}col-4 {
    width: calc(4 / 12 * 100%);
  }

  .#{$prefix}col-5 {
    width: calc(5 / 12 * 100%);
  }

  .#{$prefix}col-6 {
    width: calc(6 / 12 * 100%);
  }

  .#{$prefix}col-7 {
    width: calc(7 / 12 * 100%);
  }

  .#{$prefix}col-8 {
    width: calc(8 / 12 * 100%);
  }

  .#{$prefix}col-9 {
    width: calc(9 / 12 * 100%);
  }

  .#{$prefix}col-10 {
    width: calc(10 / 12 * 100%);
  }

  .#{$prefix}col-11 {
    width: calc(11 / 12 * 100%);
  }

  .#{$prefix}col-12 {
    width: 100%;
  }
}

@include grid-mixin("");

@each $key, $breakpoint in breakpoints.$breakpoints {
  @if map-get($breakpoint, "prefix") == "xs-" {
    @media not print {
      @media #{map-get($breakpoint, "media")} {
        @include grid-mixin(map-get($breakpoint, "prefix"));
      }
    }
  } @else {
    @media #{map-get($breakpoint, "media")} {
      @include grid-mixin(map-get($breakpoint, "prefix"));
    }
  }
}
