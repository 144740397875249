.bg-lighten-1 { background-color: var(--lighten-1); }
.bg-lighten-2 { background-color: var(--lighten-2); }
.bg-lighten-3 { background-color: var(--lighten-3); }
.bg-lighten-4 { background-color: var(--lighten-4); }

:root {
  --lighten-1: rgba(255, 255, 255, 0.0625);
  --lighten-2: rgba(255, 255, 255, 0.125);
  --lighten-3: rgba(255, 255, 255, 0.25);
  --lighten-4: rgba(255, 255, 255, 0.5);
}
