@use "./breakpoints";

@mixin margin-mixin($prefix) {
  .#{$prefix}m0 { margin: 0; }
  .#{$prefix}mt0 { margin-top: 0; }
  .#{$prefix}mr0 { margin-right: 0; }
  .#{$prefix}mb0 { margin-bottom: 0; }
  .#{$prefix}ml0 { margin-left: 0; }
  .#{$prefix}mx0 {
    margin-left: 0;
    margin-right: 0;
  }
  .#{$prefix}my0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .#{$prefix}m1 { margin: var(--space-1); }
  .#{$prefix}mt1 { margin-top: var(--space-1); }
  .#{$prefix}mr1 { margin-right: var(--space-1); }
  .#{$prefix}mb1 { margin-bottom: var(--space-1); }
  .#{$prefix}ml1 { margin-left: var(--space-1); }
  .#{$prefix}mx1 {
    margin-left: var(--space-1);
    margin-right: var(--space-1);
  }
  .#{$prefix}my1 {
    margin-top: var(--space-1);
    margin-bottom: var(--space-1);
  }

  .#{$prefix}m2 { margin: var(--space-2); }
  .#{$prefix}mt2 { margin-top: var(--space-2); }
  .#{$prefix}mr2 { margin-right: var(--space-2); }
  .#{$prefix}mb2 { margin-bottom: var(--space-2); }
  .#{$prefix}ml2 { margin-left: var(--space-2); }
  .#{$prefix}mx2 {
    margin-left: var(--space-2);
    margin-right: var(--space-2);
  }
  .#{$prefix}my2 {
    margin-top: var(--space-2);
    margin-bottom: var(--space-2);
  }

  .#{$prefix}m3 { margin: var(--space-3); }
  .#{$prefix}mt3 { margin-top: var(--space-3); }
  .#{$prefix}mr3 { margin-right: var(--space-3); }
  .#{$prefix}mb3 { margin-bottom: var(--space-3); }
  .#{$prefix}ml3 { margin-left: var(--space-3); }
  .#{$prefix}mx3 {
    margin-left: var(--space-3);
    margin-right: var(--space-3);
  }
  .#{$prefix}my3 {
    margin-top: var(--space-3);
    margin-bottom: var(--space-3);
  }

  .#{$prefix}m4 { margin: var(--space-4); }
  .#{$prefix}mt4 { margin-top: var(--space-4); }
  .#{$prefix}mr4 { margin-right: var(--space-4); }
  .#{$prefix}mb4 { margin-bottom: var(--space-4); }
  .#{$prefix}ml4 { margin-left: var(--space-4); }
  .#{$prefix}mx4 {
    margin-left: var(--space-4);
    margin-right: var(--space-4);
  }
  .#{$prefix}my4 {
    margin-top: var(--space-4);
    margin-bottom: var(--space-4);
  }

  .#{$prefix}mxn1 {
    margin-left: calc(var(--space-1) * -1);
    margin-right: calc(var(--space-1) * -1);
  }
  .#{$prefix}mxn2 {
    margin-left: calc(var(--space-2) * -1);
    margin-right: calc(var(--space-2) * -1);
  }
  .#{$prefix}mxn3 {
    margin-left: calc(var(--space-3) * -1);
    margin-right: calc(var(--space-3) * -1);
  }
  .#{$prefix}mxn4 {
    margin-left: calc(var(--space-4) * -1);
    margin-right: calc(var(--space-4) * -1);
  }

  .#{$prefix}m-auto { margin: auto; }
  .#{$prefix}mt-auto { margin-top: auto; }
  .#{$prefix}mr-auto { margin-right: auto; }
  .#{$prefix}mb-auto { margin-bottom: auto; }
  .#{$prefix}ml-auto { margin-left: auto; }
  .#{$prefix}mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .#{$prefix}my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}

@include margin-mixin("");

@each $key, $breakpoint in breakpoints.$breakpoints {
  @if map-get($breakpoint, "prefix") == "xs-" {
    @media not print {
      @media #{map-get($breakpoint, "media")} {
        @include margin-mixin(map-get($breakpoint, "prefix"));
      }
    }
  } @else {
    @media #{map-get($breakpoint, "media")} {
      @include margin-mixin(map-get($breakpoint, "prefix"));
    }
  }
}

:root {
  --space-1: 0.5rem;
  --space-2: 1rem;
  --space-3: 2rem;
  --space-4: 4rem;
}
