@use "./breakpoints";

@mixin padding-mixin($prefix) {
  .#{$prefix}p0 { padding: 0 !important; }
  .#{$prefix}pt0 { padding-top: 0 !important; }
  .#{$prefix}pr0 { padding-right: 0 !important; }
  .#{$prefix}pb0 { padding-bottom: 0 !important; }
  .#{$prefix}pl0 { padding-left: 0 !important; }
  .#{$prefix}px0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .#{$prefix}py0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .#{$prefix}p1 { padding: var(--space-1) !important; }
  .#{$prefix}pt1 { padding-top: var(--space-1) !important; }
  .#{$prefix}pr1 { padding-right: var(--space-1) !important; }
  .#{$prefix}pb1 { padding-bottom: var(--space-1) !important; }
  .#{$prefix}pl1 { padding-left: var(--space-1) !important; }
  .#{$prefix}py1 {
    padding-top: var(--space-1) !important;
    padding-bottom: var(--space-1) !important;
  }
  .#{$prefix}px1 {
    padding-left: var(--space-1) !important;
    padding-right: var(--space-1) !important;
  }

  .#{$prefix}p2 { padding: var(--space-2) !important; }
  .#{$prefix}pt2 { padding-top: var(--space-2) !important; }
  .#{$prefix}pr2 { padding-right: var(--space-2) !important; }
  .#{$prefix}pb2 { padding-bottom: var(--space-2) !important; }
  .#{$prefix}pl2 { padding-left: var(--space-2) !important; }
  .#{$prefix}py2 {
    padding-top: var(--space-2) !important;
    padding-bottom: var(--space-2) !important;
  }
  .#{$prefix}px2 {
    padding-left: var(--space-2) !important;
    padding-right: var(--space-2) !important;
  }

  .#{$prefix}p3 { padding: var(--space-3) !important; }
  .#{$prefix}pt3 { padding-top: var(--space-3) !important; }
  .#{$prefix}pr3 { padding-right: var(--space-3) !important; }
  .#{$prefix}pb3 { padding-bottom: var(--space-3) !important; }
  .#{$prefix}pl3 { padding-left: var(--space-3) !important; }
  .#{$prefix}py3 {
    padding-top: var(--space-3) !important;
    padding-bottom: var(--space-3) !important;
  }
  .#{$prefix}px3 {
    padding-left: var(--space-3) !important;
    padding-right: var(--space-3) !important;
  }

  .#{$prefix}p4 { padding: var(--space-4) !important; }
  .#{$prefix}pt4 { padding-top: var(--space-4) !important; }
  .#{$prefix}pr4 { padding-right: var(--space-4) !important; }
  .#{$prefix}pb4 { padding-bottom: var(--space-4) !important; }
  .#{$prefix}pl4 { padding-left: var(--space-4) !important; }
  .#{$prefix}py4 {
    padding-top: var(--space-4) !important;
    padding-bottom: var(--space-4) !important;
  }
  .#{$prefix}px4 {
    padding-left: var(--space-4) !important;
    padding-right: var(--space-4) !important;
  }
}

@include padding-mixin("");

@each $key, $breakpoint in breakpoints.$breakpoints {
  @if map-get($breakpoint, "prefix") == "xs-" {
    @media not print {
      @media #{map-get($breakpoint, "media")} {
        @include padding-mixin(map-get($breakpoint, "prefix"));
      }
    }
  } @else {
    @media #{map-get($breakpoint, "media")} {
      @include padding-mixin(map-get($breakpoint, "prefix"));
    }
  }
}

:root {
  --space-1: 0.5rem;
  --space-2: 1rem;
  --space-3: 2rem;
  --space-4: 4rem;
}
