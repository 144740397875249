/****** Begin 'Quote Outcome' icons ******/
i.outcome-icon::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

i.outcome-icon.quote::before,
i.outcome-icon.quoted::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f058";
  color: var(--quoted-color);
}

i.outcome-icon.refer::before,
i.outcome-icon.referred::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f071";
  color: var(--referred-color);
}

i.outcome-icon.decline::before,
i.outcome-icon.declined::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f06a";
  color: var(--declined-color);
}

i.outcome-icon.success::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f058";
  color: var(--system-success);
}

i.outcome-icon.warn::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f071";
  color: var(--system-warn);
}

i.outcome-icon.danger::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f06a";
  color: var(--system-danger);
}

/****** END 'Quote Outcome' icons ******/
