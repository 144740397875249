@use "./breakpoints";

@mixin layout-mixin($prefix) {
  .#{$prefix}inline { display: inline; }
  .#{$prefix}block { display: block !important; }
  .#{$prefix}inline-block { display: inline-block; }
  .#{$prefix}inline-flex { display: inline-flex; }
  .#{$prefix}table { display: table; }
  .#{$prefix}table-row { display: table-row; }
  .#{$prefix}table-cell { display: table-cell; }
  .#{$prefix}table-fixed { table-layout: fixed; }
  .#{$prefix}border-collapse { border-collapse: collapse; }
  .#{$prefix}contents { display: contents !important; }

  .#{$prefix}overflow-hidden { overflow: hidden; }
  .#{$prefix}overflow-scroll { overflow: scroll; }
  .#{$prefix}overflow-auto { overflow: auto; }

  .#{$prefix}clearfix:before,
  .#{$prefix}clearfix:after {
    content: " ";
    display: table;
  }
  .#{$prefix}clearfix:after { clear: both; }

  .#{$prefix}left { float: left; }
  .#{$prefix}right { float: right; }

  .#{$prefix}fit { max-width: 100%; }

  .#{$prefix}max-width-1 { max-width: var(--width-1); }
  .#{$prefix}max-width-2 { max-width: var(--width-2); }
  .#{$prefix}max-width-3 { max-width: var(--width-3); }
  .#{$prefix}max-width-4 { max-width: var(--width-4); }

  .#{$prefix}border-box { box-sizing: border-box; }
}

@include layout-mixin("");

@each $key, $breakpoint in breakpoints.$breakpoints {
  @if map-get($breakpoint, "prefix") == "xs-" {
    @media not print {
      @media #{map-get($breakpoint, "media")} {
        @include layout-mixin(map-get($breakpoint, "prefix"));
      }
    }
  } @else {
    @media #{map-get($breakpoint, "media")} {
      @include layout-mixin(map-get($breakpoint, "prefix"));
    }
  }
}

:root {
  --width-1: 24rem;
  --width-2: 32rem;
  --width-3: 48rem;
  --width-4: 64rem;
}
