@use "./breakpoints";

@mixin align-mixin($prefix) {
  .#{$prefix}align-baseline { vertical-align: baseline; }
  .#{$prefix}align-top { vertical-align: top; }
  .#{$prefix}align-middle { vertical-align: middle; }
  .#{$prefix}align-bottom { vertical-align: bottom; }
}

@include align-mixin("");

@each $key, $breakpoint in breakpoints.$breakpoints {
  @if map-get($breakpoint, "prefix") == "xs-" {
    @media not print {
      @media #{map-get($breakpoint, "media")} {
        @include align-mixin(map-get($breakpoint, "prefix"));
      }
    }
  } @else {
    @media #{map-get($breakpoint, "media")} {
      @include align-mixin(map-get($breakpoint, "prefix"));
    }
  }
}
