@use "@angular/material" as mat;

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: rgba(255, 255, 255, 0.856);
$light-secondary-text: rgba(white, 0.6);
$light-disabled-text: rgba(white, 0.4);
$light-dividers: rgba(white, 0.1);
$light-focused: rgba(white, 0.12);
$default-primary: (
  50: #e9fae6,
  100: #c7f3c0,
  200: #a2eb96,
  300: #7ce26b,
  400: #60dc4c,
  500: #41c300,
  600: #3ed127,
  700: #35cc21,
  800: #2dc61b,
  900: #1fbc10,
  A100: #ecffeb,
  A200: #bcffb8,
  A400: #8cff85,
  A700: #75ff6b,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$default-accent: (
  50: #e9eeff,
  100: #c8d6ff,
  200: #a3baff,
  300: #7e9eff,
  400: #638aff,
  500: #4775ff,
  600: #406dff,
  700: #3762ff,
  800: #2f58ff,
  900: #2045ff,
  A100: #fff,
  A200: #fff,
  A400: #cfd6ff,
  A700: #b6c0ff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$dark-accent: (
  50: #e1eaff,
  100: #b4caff,
  200: #82a6ff,
  300: #5082ff,
  400: #2b68ff,
  500: #054dff,
  600: #0446ff,
  700: #043dff,
  800: #0334ff,
  900: #0125ff,
  A100: #fff,
  A200: #f2f3ff,
  A400: #bfc5ff,
  A700: #a6aeff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$default-warn: (
  50: #ffe8e0,
  100: #ffc5b3,
  200: #ff9e80,
  300: #ff774d,
  400: #ff5a26,
  500: #ff3d00,
  600: #ff3700,
  700: #ff2f00,
  800: #ff2700,
  900: #ff1a00,
  A100: #fff,
  A200: #fff3f2,
  A400: #ffc3bf,
  A700: #ffaba6,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

// Background palette for light themes.
$light-theme-background-palette: (
  status-bar: mat.get-color-from-palette(mat.$grey-palette, 300),
  app-bar: mat.get-color-from-palette(mat.$grey-palette, 100),
  background: mat.get-color-from-palette(mat.$grey-palette, 50),
  hover: rgba(black, 0.04),
  // TODO(kara): check style with Material Design UX
  card: white,
  dialog: white,
  disabled-button: rgba(black, 0.12),
  raised-button: white,
  focused-button: $dark-focused,
  selected-button: mat.get-color-from-palette(mat.$grey-palette, 300),
  selected-disabled-button: mat.get-color-from-palette(mat.$grey-palette, 400),
  disabled-button-toggle: mat.get-color-from-palette(mat.$grey-palette, 200),
  unselected-chip: mat.get-color-from-palette(mat.$grey-palette, 300),
  disabled-list-option: mat.get-color-from-palette(mat.$grey-palette, 200),
  tooltip: mat.get-color-from-palette(mat.$grey-palette, 700),
  table-row-alt: mat.get-color-from-palette(mat.$grey-palette, 50),
  secondary-card: rgba(black, 0.04),
  binder-color: white,
);

// Background palette for dark themes.
$dark-theme-background-palette: (
  status-bar: black,
  app-bar: mat.get-color-from-palette(mat.$grey-palette, 900),
  background: #303030,
  hover: rgba(white, 0.04),
  // TODO(kara): check style with Material Design UX
  card: #121212,
  // mat-color($mat-grey, 900),
  dialog: mat.get-color-from-palette(mat.$grey-palette, 800),
  disabled-button: rgba(white, 0.12),
  raised-button: mat.get-color-from-palette(mat.$grey-palette, 800),
  focused-button: $light-focused,
  selected-button: mat.get-color-from-palette(mat.$grey-palette, 900),
  selected-disabled-button: mat.get-color-from-palette(mat.$grey-palette, 800),
  disabled-button-toggle: black,
  unselected-chip: mat.get-color-from-palette(mat.$grey-palette, 700),
  disabled-list-option: black,
  tooltip: mat.get-color-from-palette(mat.$grey-palette, 700),
  table-row-alt: mat.get-color-from-palette(mat.$grey-palette, 800),
  secondary-card: rgba(white, 0.16),
  binder-color: #424242,
);

// Foreground palette for light themes.
$light-theme-foreground-palette: (
  base: black,
  base-inverse: white,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba(black, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  hint-text: $dark-disabled-text,
  secondary-text: $dark-secondary-text,
  tertiary-text: rgba(black, 0.54),
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: rgba(black, 0.67),
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
  table-avatar: #b0b0b0,
  table-avatar-active: #444,
);

// Foreground palette for dark themes.
$dark-theme-foreground-palette: (
  base: white,
  base-inverse: black,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba(white, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-secondary-text,
  tertiary-text: rgba(white, 0.5),
  icon: white,
  icons: white,
  text: rgba(white, 0.67),
  slider-min: white,
  slider-off: rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3),
  table-avatar: rgba(61, 61, 61, 0.48),
  table-avatar-active: black,
);

// Status Colors
$ready-color: var(--ready-color);
$submitted-color: var(--submitted-color);
$quote-color: var(--quoted-color);
$refer-color: var(--referred-color);
$decline-color: var(--declined-color);
$bound-color: var(--bound-color);
$issued-color: var(--issued-color);
$lost-color: var(--lost-color);
$closed-color: var(--closed-color);
$missed-color: var(--missed-color);
$awaitinginformation-color: var(--awaitinginformation-color);
$inprogress-color: var(--inprogress-color);

// System Colors
$system-success: var(--system-success);
$system-danger: var(--system-danger);
$system-warn: var(--system-warn);
$system-info: var(--system-info);

:root {
  --primary-color: #3f51b5;
  --ready-color: #05f;
  --submitted-color: #fcca03;
  --quoted-color: #41c300;
  --referred-color: #ffe914;
  --declined-color: #f00;
  --bound-color: #06deff;
  --issued-color: #7846e1;
  --lost-color: #ccc;
  --closed-color: #ccc;
  --missed-color: #b5bbbe;
  --awaitinginformation-color: #f80;
  --inprogress-color: #ffe914;

  // System Colors
  --system-success: #61c452;
  --system-danger: #ff3d00;
  --system-warn: #ffd600;
  --system-info: #00b8d4;
}
