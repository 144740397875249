@use "./breakpoints";

@mixin typography-mixin($prefix) {
  .#{$prefix}font-family-inherit { font-family: inherit; }
  .#{$prefix}font-size-inherit { font-size: inherit; }
  .#{$prefix}text-decoration-none { text-decoration: none; }

  .#{$prefix}bold { font-weight: var(--bold-font-weight, bold); }
  .#{$prefix}regular { font-weight: normal; }
  .#{$prefix}font-weight-400 { font-weight: 400; }
  .#{$prefix}font-weight-500 { font-weight: 500; }
  .#{$prefix}font-weight-600 { font-weight: 600; }
  .#{$prefix}font-weight-700 { font-weight: 700; }
  .#{$prefix}italic { font-style: italic; }
  .#{$prefix}caps {
    text-transform: uppercase;
  }

  .#{$prefix}left-align { text-align: left; }
  .#{$prefix}center { text-align: center; }
  .#{$prefix}right-align { text-align: right; }
  .#{$prefix}justify { text-align: justify; }

  .#{$prefix}nowrap { white-space: nowrap; }
  .#{$prefix}normal { white-space: normal; }
  .#{$prefix}break-word { word-wrap: break-word; }

  .#{$prefix}line-height-1 { line-height: var(--line-height-1) !important; }
  .#{$prefix}line-height-2 { line-height: var(--line-height-2); }
  .#{$prefix}line-height-3 { line-height: var(--line-height-3); }
  .#{$prefix}line-height-4 { line-height: var(--line-height-4); }

  .#{$prefix}list-style-none { list-style: none; }
  .#{$prefix}underline { text-decoration: underline; }

  .#{$prefix}line-through {
    text-decoration: line-through;

    a,
    a:visited {
      color: unset;
    }
  }

  .#{$prefix}truncate {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .#{$prefix}truncate-calc {
    max-width: calc(100%);
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .#{$prefix}list-reset {
    list-style: none;
    padding-left: 0;
  }
}

@include typography-mixin("");

@each $key, $breakpoint in breakpoints.$breakpoints {
  @if map-get($breakpoint, "prefix") == "xs-" {
    @media not print {
      @media #{map-get($breakpoint, "media")} {
        @include typography-mixin(map-get($breakpoint, "prefix"));
      }
    }
  } @else {
    @media #{map-get($breakpoint, "media")} {
      @include typography-mixin(map-get($breakpoint, "prefix"));
    }
  }
}

:root {
  --line-height-1: 1;
  --line-height-2: 1.125;
  --line-height-3: 1.25;
  --line-height-4: 1.5;
  --caps-letter-spacing: 0.2em;
  --bold-font-weight: bold;
}
