// Import library functions for theme creation.
@use "@angular/material" as mat;

@mixin color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat.get-color-config($config-or-theme);

  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);

  // $accent: map-get($config, accent);
  $foreground: map-get($config, foreground);
}

@mixin typography($config-or-theme) {
  // Extract the typography configuration in case a theme has been passed.
  $config: mat.get-typography-config($config-or-theme);
}

@mixin theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  carrier-submission-status-history-dialog {
    .mat-list-item-content {
      align-items: flex-start !important;
      padding-left: 0 !important;
    }

    .status-mini-0 {
      color: var(--awaitinginformation-color);
    }

    .status-mini-1 {
      color: var(--referred-color);
    }

    .status-mini-2 {
      color: var(--quoted-color);
    }

    .status-mini-3 {
      color: var(--bound-color);
    }

    .status-mini-4 {
      color: var(--issued-color);
    }

    .status-mini-5 {
      color: var(--declined-color);
    }

    .status-mini-6 {
      color: var(--lost-color);
    }

    .status-mini-7 {
      color: var(--missed-color);
    }

    .status-mini-8 {
      color: var(--inprogress-color);
    }

    .status-mini-9 {
      color: var(--closed-color);
    }

    .mat-list-item {
      height: 80px !important;
      margin-bottom: 8px !important;
    }

    .mat-icon {
      font-size: 16px !important;
      padding: 0 !important;
      width: 24px !important;
      display: flex !important;
      margin-right: 8px !important;
    }

    h3 {
      margin-bottom: 8px !important;
    }

    .mat-line small {
      color: #888;
    }

    .mat-subheader {
      padding: 0 !important;
    }

    p.flex {
      display: flex !important;
      font-weight: 500 !important;
    }

    p {
      margin-bottom: 4px !important;
    }

    .mat-list-base .mat-subheader {
      height: 24px !important;
    }

    .status-wrap {
      border-bottom: 1px solid rgb(231, 231, 231);
      margin-bottom: 8px !important;
      padding-bottom: 8px !important;
    }

    .left-line {
      position: relative !important;
      height: 100%;
      padding-left: 32px;
    }

    .left-line::before {
      display: inline-block !important;
      background: #dee2e6;
      border-radius: 2px;
      content: "";
      height: 100%;
      left: 11px;
      position: absolute;
      width: 2px;
    }

    .user-info {
      padding-bottom: 8px;
      margin-bottom: 6px;
      padding-top: 6px;
    }

    .status-wrap > div {
      margin-bottom: 8px !important;
    }

    .active-status {
      opacity: 1 !important;
      border-radius: 4px;
      padding-top: 8px;
      padding-bottom: 12px;
      margin-bottom: 16px !important;

      .left-line::before {
        background: none !important;
      }

      .status-wrap:first-of-type {
        border-bottom: none !important;
        margin-bottom: 0 !important;
      }
    }

    .last-status {
      .left-line::before {
        background: none !important;
      }

      .left-line .user-info {
        border-bottom: none !important;
      }
    }

    ol.list-reset {
      margin-top: 0 !important;
    }
  }

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color {
    @include color($color);
  }

  @if $typography {
    @include typography($typography);
  }
}
