/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@use "./assets/vertigo-icons/style.css";
@use "./styles/outcome-icons";
@use "./styles/palette";
@use "./styles/vertigo-typography";
@use "./styles/utils/align";
@use "./styles/utils/border";
@use "./styles/utils/flexbox";
@use "./styles/utils/grid";
@use "./styles/utils/hide";
@use "./styles/utils/layout";
@use "./styles/utils/margin";
@use "./styles/utils/padding";
@use "./styles/utils/position";
@use "./styles/utils/type-scale";
@use "./styles/utils/typography";
@use "./styles/utils/background-colors";
@use "./styles/utils/colors";
@use "./styles/utils/border-colors";
@use "./styles/utils/darken";
@use "./styles/utils/lighten";
@use "./styles/utils/breakpoints";
@use "./styles/components/mat-table";
@use "./styles/components/mat-chip";
@use "./styles/components/mat-button-toggle-group-nav";
@use "./styles/components/mat-card-secondary";
@use "./app/shared/components/system/incremental-number-input/incremental-number-input.component.theme"
as incremental-number-input;
@use "./app/shared/components/dashboard/widgets/quote-report-summary-card-widget/quote-report-summary-card-widget.component.theme"
as quote-report-summary-card-widget;
@use "./app/shared/components/layout/page-header/page-header.component.theme" as page-header;
@use "./app/shared/components/layout/right-sidenav/right-sidenav.component.theme"
as right-sidenav;
@use "./app/shared/components/dashboard/widget/widget.component.theme" as widget;
@use "./app/shared/components/dashboard/labeled-row/labeled-row.component.theme"
as labeled-row;
@use "./app/agency/submission/overview/submission-overview.component.theme" as agency-submission-overview;
@use "./app/carrier/submission/dialogs/status-history/status-history.component.scss-theme"
as carrier-status-history;
@use "./styles/components/ngx-slider-theme" as ngx-slider;
@use "./styles/components/mat-form-field" as mat-form-field;
@import "../node_modules/@fortawesome/fontawesome-pro/css/all.css";

html,
body {
  height: 100vh;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat.core(vertigo-typography.$vertigo-custom-typography);

$dark-text: #000;
$light-text: #fff;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$light-primary: (
  main: #000781,
  lighter: #4c33b2,
  darker: #000053,
  200: #000781,
  // For slide toggle,
  contrast: (main: $light-text,
    lighter: $light-text,
    darker: $light-text,
  ),
);
$light-accent: (
  main: #70f5d5,
  lighter: #a7ffff,
  darker: #33c2a4,
  200: #70f5d5,
  // For slide toggle,
  contrast: (main: $dark-text,
    lighter: $dark-text,
    darker: $dark-text,
  ),
);
$light-warn: (
  main: #ef5350,
  lighter: #ff867c,
  darker: #b61827,
  200: #ef5350,
  // For slide toggle,
  contrast: (main: $dark-text,
    lighter: $dark-text,
    darker: $dark-text,
  ),
);
$vertigo-theme: mat.define-light-theme((color: (primary: mat.define-palette($light-primary, main, lighter, darker),
        accent: mat.define-palette($light-accent, main, lighter, darker),
        warn: mat.define-palette($light-warn, main, lighter, darker),
      ),
    ));
$vertigo-theme: mat.define-light-theme((color: (primary: mat.define-palette($light-primary, main, lighter, darker),
        accent: mat.define-palette($light-accent, main, lighter, darker),
        warn: mat.define-palette($light-warn, main, lighter, darker),
      ),
    ));

// Include the default theme styles (color and default density)
@include mat.all-component-themes($vertigo-theme);
@include mat-table.theme($vertigo-theme);
@include mat-chip.theme($vertigo-theme);
@include mat-button-toggle-group-nav.theme($vertigo-theme);
@include carrier-status-history.theme($vertigo-theme);
@include incremental-number-input.theme($vertigo-theme);
@include quote-report-summary-card-widget.theme($vertigo-theme);
@include agency-submission-overview.theme($vertigo-theme);
@include page-header.theme($vertigo-theme);
@include right-sidenav.theme($vertigo-theme);
@include widget.theme($vertigo-theme);
@include labeled-row.theme($vertigo-theme);
@include colors.color($vertigo-theme);
@include background-colors.color($vertigo-theme);
@include border-colors.color($vertigo-theme);
@include mat-card-secondary.color($vertigo-theme);
@include mat-button-toggle-group-nav.theme($vertigo-theme);
@include ngx-slider.theme($vertigo-theme);
@include mat-form-field.theme($vertigo-theme);

.mat-toolbar.app-toolbar {
  z-index: 6;
}

.mat-tab-header {
  z-index: 4;
  background: map-get(mat.$light-theme-background-palette, "background");
}

app-toast {
  z-index: 100;
}

@each $key,
$breakpoint in breakpoints.$breakpoints {
  @media #{map-get($breakpoint, "bounded-media")} {
    @if map-get(map-get($breakpoint, "app-toolbar"), "position")==sticky {
      .mat-toolbar.app-toolbar {
        position: sticky;
        top: 0;
      }

      .mat-tab-header {
        position: sticky !important;
        top: calc(64px + 100px);
      }
    }

    .app-page-header {
      position: sticky !important;
      top: #{map-get(map-get($breakpoint, "app-page-header"), "top")} !important;
    }

    .content-full-height {
      height: calc(100vh - #{map-get(map-get($breakpoint, "app-toolbar"), "height")});
    }

    .page-content-spacing {
      margin: #{map-get($breakpoint, "page-content-spacing")};
    }
  }
}

a,
a:visited {
  text-decoration: none;
  color: #000;
}

[role="button"] {
  cursor: pointer;
}

.mat-tab-label {
  padding: 0 10px !important;
  min-width: 120px !important;
}

.mat-paginator {
  background: transparent;
}

/***** BEGIN SIDENAV CONTAINER *****/
.app-sidenav-container {
  height: 100%;

  &.mat-drawer-container {
    height: 100vh;
  }

  // Left sidenav
  .app-sidenav {
    width: 250px;

    .mat-list-base .mat-subheader {
      color: whitesmoke;
    }

    .mat-button,
    .mat-icon-button,
    .mat-stroked-button {
      color: white;
    }

    .mat-nav-list a {
      color: white;
    }

    .mat-expansion-panel {
      background: #3f51b5;
      background: linear-gradient(110deg,
          rgb(0 7 129 / 100%) 0%,
          rgb(0 7 129 / 100%) 100%);
    }

    &.mat-drawer {
      background: #3f51b5;
      background: linear-gradient(110deg,
          rgba(0, 7, 129, 1) 0%,
          rgba(0, 7, 129, 1) 100%);
    }

    // removes app left sidenav border
    &.mat-drawer-side {
      border: 0 !important;
    }

    .mat-drawer-inner-container {
      overflow: hidden !important;

      .mat-toolbar {
        height: 160px !important;
      }
    }
  }

  // content area
  .app-sidenav-content {
    // height: 100vh;
    // overflow-x: hidden !important;

    /***** BEGIN TOOLBAR *****/
    .app-toolbar {
      .user-avatar-image {
        border-radius: 64px;
        width: 32px;
        height: 32px;
      }

      .user-wrap {
        line-height: 16px;
      }

      .global-search {
        font-size: 14px !important;

        .mat-form-field-appearance-fill .mat-form-field-flex {
          border-radius: 64px !important;
          height: 32px !important;
        }

        .mat-form-field-wrapper {
          padding-bottom: 0 !important;
        }

        .mat-form-field .mat-form-field-underline {
          visibility: hidden !important;
        }

        .mat-form-field-prefix {
          margin: 0 8px !important;
        }

        .mat-form-field-suffix {
          line-height: 1.2 !important;
        }

        .mat-form-field-infix {
          border: 0 !important;
          padding: 0 !important;
          margin: 0 !important;
        }

        .mat-form-field-flex {
          margin: 0 !important;
          padding: 0 8px !important;
          align-items: center !important;
        }
      }

      .mat-toolbar form {
        line-height: 1 !important;
      }

      .toolbar-user {
        padding: 0 !important;
        border-radius: 32px !important;
      }
    }

    /******* END TOOLBAR *****/
  }
}

/***** END SIDENAV *****/

/****** End Third Theme - Transparent theme ******/

@media print {

  body,
  mat-sidenav-container,
  #section-to-print {
    display: block !important;
    position: relative !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    margin: 0 !important;
  }

  .mat-tab-body-content,
  .mat-tab-body {
    overflow: visible !important;
  }

  .color-base-text {
    color: black !important;
  }

  mat-sidenav,
  mat-sidenav *,
  mat-toolbar,
  mat-toolbar *,
  app-page-header,
  app-page-header *,
  mat-tab-header,
  mat-tab-header *,
  .no-print {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
  }

  .mat-tab-body-wrapper,
  mat-sidenav-content {
    margin: 0 !important;
  }

  mat-card.mat-card {
    box-shadow: none !important;
  }
}

h2.m0 {
  margin: 0 !important;
}

.dashboard {
  display: flex !important;
}

.dashboard app-file-upload > div {
  display: flex;
  flex-grow: 1;
}

// Light scrollbar styling

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: map-get(mat.$light-theme-background-palette, "background");
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: map-get(mat.$light-theme-background-palette, "status-bar");
  border-radius: 16px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: map-get(mat.$light-theme-background-palette, "background");
}

.mat-icon-button.dense {
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.multiline-autocomplete {
  &.mat-autocomplete-panel mat-option.mat-option {
    margin: 1rem 0;
    overflow: visible;
    line-height: initial;
    height: initial;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .mat-option-text.mat-option-text {
    white-space: normal;
  }
}
