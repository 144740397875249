@use "./breakpoints";

@mixin border-mixin($prefix) {

  .#{$prefix}border {
    border-style: solid;
    border-width: var(--border-width);
  }

  .#{$prefix}border-none { border: 0; }

  .#{$prefix}border-top {
    border-top-style: solid;
    border-top-width: var(--border-width);
  }

  .#{$prefix}border-right {
    border-right-style: solid;
    border-right-width: var(--border-width);
  }

  .#{$prefix}border-bottom {
    border-bottom-style: solid;
    border-bottom-width: var(--border-width);
  }

  .#{$prefix}border-left {
    border-left-style: solid;
    border-left-width: var(--border-width);
  }

  .#{$prefix}border-dashed {
    border-style: dashed;
  }

  .#{$prefix}border-dotted {
    border-style: dotted;
  }

  .#{$prefix}border-double {
    border-style: double;
  }

  .#{$prefix}rounded { border-radius: var(--border-radius); }
  .#{$prefix}circle { border-radius: 50%; }

  .#{$prefix}not-rounded { border-radius: 0; }

  .#{$prefix}rounded-top { border-top-left-radius: var(--border-radius); }
  .#{$prefix}rounded-right { border-top-right-radius: var(--border-radius); }
  .#{$prefix}rounded-bottom { border-bottom-right-radius: var(--border-radius); }
  .#{$prefix}rounded-left { border-bottom-left-radius: var(--border-radius); }

  .#{$prefix}border-before::before {
    content: "";
    position: absolute;
    top: 0;
    left: 22px;
    width: 4px;
    background: #d4ded7bd;
    height: calc(100% - 30px);
    margin-top: 10px;
    z-index: 1;
  }

  .#{$prefix}border-after::after {
    flex: 1 1 auto;
    border-top: 1px solid;
    content: " ";
    border-color: inherit;
    margin-bottom: 16px;
  }

  .#{$prefix}border-width-2 {
    border-width: var(--border-width-2);
  }
}

@include border-mixin("");

@each $key, $breakpoint in breakpoints.$breakpoints {
  @if map-get($breakpoint, "prefix") == "xs-" {
    @media not print {
      @media #{map-get($breakpoint, "media")} {
        @include border-mixin(map-get($breakpoint, "prefix"));
      }
    }
  } @else {
    @media #{map-get($breakpoint, "media")} {
      @include border-mixin(map-get($breakpoint, "prefix"));
    }
  }
}

:root {
  --border-width: 1px;
  --border-width-2: 2px;
  --border-radius: 4px;
}
