@use "./breakpoints";

@mixin hide-mixin($prefix) {
  .#{$prefix}hide {
    display: none !important;
  }
}

@include hide-mixin("");

@each $key, $breakpoint in breakpoints.$breakpoints {
  @if map-get($breakpoint, "prefix") == "xs-" {
    @media not print {
      @media #{map-get($breakpoint, "bounded-media")} {
        @include hide-mixin(map-get($breakpoint, "prefix"));
      }
    }
  } @else {
    @media #{map-get($breakpoint, "bounded-media")} {
      @include hide-mixin(map-get($breakpoint, "prefix"));
    }
  }
}
