@use "@angular/material" as mat;

@mixin color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat.get-color-config($config-or-theme);

  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $foreground: map-get($config, foreground);
  $background: map-get($config, background);

  // Define any styles affected by the theme.
  .app-page-header {
    // Use mat-color to extract individual colors from a palette.
    // background-color: mat-color($primary);
    // border-color: mat-color($accent, A400);
    .subtitle {
      color: map-get($foreground, secondary-text);

      a,
      a:visited {
        color: map-get($foreground, secondary-text);
      }
    }

    .vicon {
      background: map-get($background, card) !important;
      color: mat.get-color-from-palette($primary, main);
    }

    background-color: map-get(
      $background,
      background
    ) !important; // backdrop-filter fallback
  }
}

@mixin typography($config-or-theme) {
  // Extract the typography configuration in case a theme has been passed.
  $config: mat.get-typography-config($config-or-theme);

  .app-page-header {
    .subtitle {
      font-size: mat.font-size($config, caption);
    }
  }
}

@mixin theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color {
    @include color($color);
  }

  @if $typography {
    @include typography($typography);
  }
}
