@use "@angular/material" as mat;

@mixin color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat.get-color-config($config-or-theme);

  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $warn: map-get($config, warn);
  $foreground: map-get($map: $config, $key: "foreground");

  .border-color-divider,
  .border-color-dividers {
    border-color: map-get($map: $foreground, $key: "divider");
  }

  .border-color-primary {
    border-color: mat.get-color-from-palette($primary);
  }

  .border-color-accent {
    border-color: mat.get-color-from-palette($accent);
  }

  .border-color-warn {
    border-color: mat.get-color-from-palette($warn);
  }

  .border-color-tertiary-text {
    border-color: map-get($map: $foreground, $key: "tertiary-text");
  }

  @each $key, $color in $primary {
    @if $key != "contrast" {
      .border-color-primary-#{$key} {
        border-color: $color;
      }
    }
  }

  @each $key, $color in $accent {
    @if $key != "contrast" {
      .border-color-accent-#{$key} {
        border-color: $color;
      }
    }
  }

  @each $key, $color in $warn {
    @if $key != "contrast" {
      .border-color-warn-#{$key} {
        border-color: $color;
      }
    }
  }
}

$colors: (
  "red": mat.$red-palette,
  "pink": mat.$pink-palette,
  "blue": mat.$blue-palette,
  "green": mat.$green-palette,
  "yellow": mat.$yellow-palette,
  "orange": mat.$orange-palette,
  "grey": mat.$grey-palette,
  "gray": mat.$gray-palette
);

@each $key, $color in $colors {
  .border-color-#{$key} {
    border-color: mat.get-color-from-palette($color, 500);
  }

  @each $color-key, $color-value in $color {
    @if $color-key != "contrast" {
      .border-color-#{$key}-#{$color-key} {
        border-color: $color-value;
      }
    }
  }
}

.border-color-ready {
  border-color: var(--ready-color);
}

.border-color-submitted {
  border-color: var(--submitted-color);
}

.border-color-quoted,
.border-color-quote {
  border-color: var(--quoted-color);
}

.border-color-referred,
.border-color-refer {
  border-color: var(--referred-color);
}

.border-color-declined,
.border-color-decline {
  border-color: var(--declined-color);
}

.border-color-bound {
  border-color: var(--bound-color);
}

.border-color-issued {
  border-color: var(--issued-color);
}

.border-color-lost {
  border-color: var(--lost-color);
}

.border-color-closed {
  border-color: var(--closed-color);
}

.border-color-missed {
  border-color: var(--missed-color);
}

.border-color-awaitinginformation {
  border-color: var(--awaitinginformation-color);
}

.border-color-inprogress {
  border-color: var(--inprogress-color);
}

// System Colors
.border-color-system-success {
  border-color: var(--system-success);
}

.border-color-system-danger {
  border-color: var(--system-danger);
}

.border-color-system-warn {
  border-color: var(--system-warn);
}

.border-color-system-info {
  border-color: var(--system-info);
}

.border-color-inherit { border-color: inherit; }
