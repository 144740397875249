@use "./breakpoints";

@mixin position-mixin($prefix) {
  .#{$prefix}relative { position: relative; }
  .#{$prefix}absolute { position: absolute; }
  .#{$prefix}fixed { position: fixed; }
  .#{$prefix}sticky { position: sticky; }
  .#{$prefix}position-unset { position: unset; }

  .#{$prefix}top-0 { top: 0; }
  .#{$prefix}right-0 { right: 0; }
  .#{$prefix}bottom-0 { bottom: 0; }
  .#{$prefix}left-0 { left: 0; }

  .#{$prefix}z1 { z-index: var(--z1); }
  .#{$prefix}z2 { z-index: var(--z2); }
  .#{$prefix}z3 { z-index: var(--z3); }
  .#{$prefix}z4 { z-index: var(--z4); }
}

@include position-mixin("");

@each $key, $breakpoint in breakpoints.$breakpoints {
  @if map-get($breakpoint, "prefix") == "xs-" {
    @media not print {
      @media #{map-get($breakpoint, "media")} {
        @include position-mixin(map-get($breakpoint, "prefix"));
      }
    }
  } @else {
    @media #{map-get($breakpoint, "media")} {
      @include position-mixin(map-get($breakpoint, "prefix"));
    }
  }
}

:root {
  --z1: 1;
  --z2: 2;
  --z3: 3;
  --z4: 4;
}
