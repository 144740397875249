@use "@angular/material" as mat;

@mixin color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat.get-color-config($config-or-theme);

  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);

  // Define any styles affected by the theme.
  .mat-button-toggle-group.mat-button-group-toggle-nav {
    // Use mat-color to extract individual colors from a palette.
    .mat-button-toggle.mat-button-toggle-checked {
      background-color: mat.get-color-from-palette($primary, main);
      color: mat.get-contrast-color-from-palette($primary, main);
    }
  }
}

@mixin typography($config-or-theme) {
  // Extract the typography configuration in case a theme has been passed.
  $config: mat.get-typography-config($config-or-theme);
}

@mixin theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  .mat-button-toggle-group.mat-button-group-toggle-nav {
    .mat-button-toggle-appearance-standard {
      .mat-button-toggle-label-content {
        line-height: 2em;
      }
    }
  }

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color {
    @include color($color);
  }

  @if $typography {
    @include typography($typography);
  }
}
