@use "./breakpoints";

@mixin type-scale-mixin($prefix) {
  .#{$prefix}h00 { font-size: var(--h00) !important; }
  .#{$prefix}h0 { font-size: var(--h0) !important; }
  .#{$prefix}h1 { font-size: var(--h1) !important; }
  .#{$prefix}h2 { font-size: var(--h2) !important; }
  .#{$prefix}h3 { font-size: var(--h3) !important; }
  .#{$prefix}h4 { font-size: var(--h4) !important; }
  .#{$prefix}h5 { font-size: var(--h5) !important; }
  .#{$prefix}h6 { font-size: var(--h6) !important; }
}

@include type-scale-mixin("");

@each $key, $breakpoint in breakpoints.$breakpoints {
  @if map-get($breakpoint, "prefix") == "xs-" {
    @media not print {
      @media #{map-get($breakpoint, "media")} {
        @include type-scale-mixin(map-get($breakpoint, "prefix"));
      }
    }
  } @else {
    @media #{map-get($breakpoint, "media")} {
      @include type-scale-mixin(map-get($breakpoint, "prefix"));
    }
  }
}

:root {
  --h00: 4rem;
  --h0: 3rem;
  --h1: 2rem;
  --h2: 1.5rem;
  --h3: 1.25rem;
  --h4: 1rem;
  --h5: 0.875rem;
  --h6: 0.75rem;
}
