@use "./breakpoints";

@mixin flexbox-mixin($prefix) {

  .#{$prefix}flex { display: flex; }
  .#{$prefix}inline-flex { display: inline-flex; }

  .#{$prefix}flex-row { flex-direction: row; }
  .#{$prefix}flex-column { flex-direction: column; }
  .#{$prefix}flex-row-reverse { flex-direction: row-reverse; }
  .#{$prefix}flex-column-reverse { flex-direction: column-reverse; }
  .#{$prefix}flex-wrap { flex-wrap: wrap; }
  .#{$prefix}flex-nowrap { flex-wrap: nowrap; }

  .#{$prefix}items-start { align-items: flex-start; }
  .#{$prefix}items-end { align-items: flex-end; }
  .#{$prefix}items-center { align-items: center; }
  .#{$prefix}items-baseline { align-items: baseline; }
  .#{$prefix}items-stretch { align-items: stretch; }

  .#{$prefix}self-start { align-self: flex-start; }
  .#{$prefix}self-end { align-self: flex-end; }
  .#{$prefix}self-center { align-self: center; }
  .#{$prefix}self-baseline { align-self: baseline; }
  .#{$prefix}self-stretch { align-self: stretch; }

  .#{$prefix}justify-start { justify-content: flex-start; }
  .#{$prefix}justify-end { justify-content: flex-end; }
  .#{$prefix}justify-center { justify-content: center; }
  .#{$prefix}justify-between { justify-content: space-between; }
  .#{$prefix}justify-around { justify-content: space-around; }
  .#{$prefix}justify-evenly { justify-content: space-evenly; }

  .#{$prefix}content-start { align-content: flex-start; }
  .#{$prefix}content-end { align-content: flex-end; }
  .#{$prefix}content-center { align-content: center; }
  .#{$prefix}content-between { align-content: space-between; }
  .#{$prefix}content-around { align-content: space-around; }
  .#{$prefix}content-stretch { align-content: stretch; }

  /* 1. Fix for Chrome 44 bug. https://code.google.com/p/chromium/issues/detail?id=506893 */
  .#{$prefix}flex-auto {
    flex: 1 1 auto;
    min-width: 0; /* 1 */
    min-height: 0; /* 1 */
  }
  .#{$prefix}flex-none { flex: none; }
  .#{$prefix}flex-unset { flex: unset; }
  .#{$prefix}flex-1 { flex: 1; }
  .#{$prefix}flex-2 { flex: 2; }
  .#{$prefix}flex-3 { flex: 3; }
  .#{$prefix}flex-4 { flex: 4; }
  .#{$prefix}flex-5 { flex: 5; }
  .#{$prefix}flex-6 { flex: 6; }

  .#{$prefix}order-0 { order: 0; }
  .#{$prefix}order-1 { order: 1; }
  .#{$prefix}order-2 { order: 2; }
  .#{$prefix}order-3 { order: 3; }
  .#{$prefix}order-4 { order: 4; }
  .#{$prefix}order-5 { order: 5; }
  .#{$prefix}order-6 { order: 6; }
  .#{$prefix}order-last { order: 99999; }

  .#{$prefix}flex-col-12 { flex: 100%; }
  .#{$prefix}flex-col-11 { flex: calc(100% * (11 / 12)); }
  .#{$prefix}flex-col-10 { flex: calc(100% * (10 / 12)); }
  .#{$prefix}flex-col-9 { flex: calc(100% * (9 / 12)); }
  .#{$prefix}flex-col-8 { flex: calc(100% * (8 / 12)); }
  .#{$prefix}flex-col-7 { flex: calc(100% * (7 / 12)); }
  .#{$prefix}flex-col-6 { flex: 50%; }
  .#{$prefix}flex-col-5 { flex: calc(100% * (5 / 12)); }
  .#{$prefix}flex-col-4 { flex: calc(100% * (4 / 12)); }
  .#{$prefix}flex-col-3 { flex: calc(100% * (3 / 12)); }
  .#{$prefix}flex-col-2 { flex: calc(100% * (2 / 12)); }
  .#{$prefix}flex-col-1 { flex: calc(100% * (1 / 12)); }

  .#{$prefix}flex-grow-0 { flex-grow: 0 !important; }
  .#{$prefix}flex-grow-1 { flex-grow: 1 !important; }
  .#{$prefix}flex-grow-2 { flex-grow: 2 !important; }
  .#{$prefix}flex-grow-3 { flex-grow: 3 !important; }
  .#{$prefix}flex-grow-4 { flex-grow: 4 !important; }

  .#{$prefix}gap-1 { gap: var(--space-1); }
  .#{$prefix}gap-2 { gap: var(--space-2); }
  .#{$prefix}gap-3 { gap: var(--space-3); }
  .#{$prefix}gap-4 { gap: var(--space-4); }

  .#{$prefix}column-gap-1 { column-gap: var(--space-1); }
  .#{$prefix}column-gap-2 { column-gap: var(--space-2); }
  .#{$prefix}column-gap-3 { column-gap: var(--space-3); }
  .#{$prefix}column-gap-4 { column-gap: var(--space-4); }

  .#{$prefix}row-gap-1 { row-gap: var(--space-1); }
  .#{$prefix}row-gap-2 { row-gap: var(--space-2); }
  .#{$prefix}row-gap-3 { row-gap: var(--space-3); }
  .#{$prefix}row-gap-4 { row-gap: var(--space-4); }
}

@include flexbox-mixin("");

@each $key, $breakpoint in breakpoints.$breakpoints {
  @if map-get($breakpoint, "prefix") == "xs-" {
    @media not print {
      @media #{map-get($breakpoint, "media")} {
        @include flexbox-mixin(map-get($breakpoint, "prefix"));
      }
    }
  } @else {
    @media #{map-get($breakpoint, "media")} {
      @include flexbox-mixin(map-get($breakpoint, "prefix"));
    }
  }
}

:root {
  --space-1: 0.5rem;
  --space-2: 1rem;
  --space-3: 2rem;
  --space-4: 4rem;
}
